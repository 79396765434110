import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import productData from "../../static/data/product"

import QuickBuyPopup from "../components/QuickBuyPopup";

import { UseShopifyDataQuery } from "../hooks/shopifyData";
import NavCardMenu from "../components/NavCardMenu";
import HeroCarousel from "../components/HeroCarousel";
import Testimonial from "../components/Testimonial";
import ProductBoxContainer from "../components/ProductBoxContainer";

const Home = () => {

    const [isOpenPopup, setOpenPopup] = useState(false)
    const [clickedProduct, setClickedProduct] = useState(false)
    const [shopifyItem, setShopifyItem] = useState('')
    const [country, setCountry] = useState('US')

    useEffect(()=>{
        setCountry(window.localStorage.getItem("country"))
    },[])

    const [pageLoader, setPageLoader] = useState(true);

    const shopifyData = UseShopifyDataQuery();

    useEffect(()=>{
        if(window.localStorage.getItem("country")) {
            setPageLoader(false);
        }
    }, [shopifyData])

    const handlePopup = (isOpen) => {
        setOpenPopup(isOpen)
    }
    const handleShopify = (item) => {
        setShopifyItem(item)
    }
    const handleProduct = (product) => {
        setClickedProduct(product)
    }


    return (
        <>
            <Helmet>
                {/*OG SHARE*/}
                <meta property="og:title" content="The Betty Rocker"/>
                <meta property="og:description" content="Whole food recipes and Home Workouts for Your Healthy Lifestyle of Awesome!"/>
                <meta property="og:url" content=""/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://mk0thebettyrockber7x.kinstacdn.com/wp-content/uploads/2019/05/v2.jpg"/>
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="628" />
                <meta property="og:image:alt" content="Image of The Betty Rocker." />

                {/*TWITTER CARDS*/}
                <meta name="twitter:title" content="The Betty Rocker"/>
                <meta name="twitter:description" content="Whole food recipes and Home Workouts for Your Healthy Lifestyle of Awesome!"/>
                <meta name="twitter:image" content="https://mk0thebettyrockber7x.kinstacdn.com/wp-content/uploads/2019/05/v2.jpg"/>
                <meta name="twitter:image:alt" content="Image of The Betty Rocker." />
                <meta name="twitter:card" content="summary_large_image"/>
          </Helmet>

            <Layout title="The Betty Rocker" isLoader={pageLoader}>
                {/*hero*/}
                <HeroCarousel type="homepage" />

                {/*nav cards*/}
                <NavCardMenu />

                {/*product box - WORKOUT PLANS*/}
                <section className="container container--xxxl py--20">
                  <h2 className="h2--xs txt--uppercase mb--40"><span className="txt--pink">workout</span> plans</h2>

                  <div className="row justify-content-center">
                      {
                          Object.keys(productData).map((id) => (
                              shopifyData.map((item, index) => (
                                  <ProductBoxContainer
                                      key={index}
                                      productId={id}
                                      variantId={item.variants[0].id}
                                      type="workout plan"
                                      item={item}
                                      handlePopup={handlePopup}
                                      handleProduct={handleProduct}
                                      handleShopify={handleShopify}
                                  />
                              ))
                          ))
                      }
                  </div>
                </section>

                {/*rock your life*/}
                <section className="bg--lightgray">
                  <div className="container container--smd">
                      <div className="banner-box">
                          <div className="banner-box__header banner-box__header--ryl">
                              <div className="row">
                                  <div className="col-12 col-md-7 col-lg-5">
                                      <h2 className="txt--uppercase mb--10 txt--left">rock your life</h2>
                                      <p className="txt--white"><strong className="fw--bold">Our empowering, positive, uplifting community will welcome and support you as you progress forward.</strong></p>
                                  </div>
                              </div>
                          </div>
                          <div className="banner-box__content">
                              <div className="row">
                                  <div className="col-12 col-md-5 order-2 order-md-1 txt--center">
                                      <img src="/images/alt-calendars/calendars-desktop.png" width="1366" height="1838" className="lazy d-none d-md-inline-block shadow-0" alt=""/>
                                      <img src="/images/alt-calendars/calendars-mob.png" width="650" height="612" className="lazy d-inline-block d-md-none shadow-0 mb--10" alt=""/>

                                      <div className="txt--center d-block d-md-none">
                                          <button className="btn btn--md">30-day trial</button>
                                      </div>
                                  </div>
                                  <div className="col-12 col-md-7 order-1 order-md-2">
                                      <ul>
                                          <li>5 new classes you can do from home every week for constant variety and fun! Drop in on your schedule - they're all loaded right into your workout library!</li>
                                          <li>Choose a workout from hundreds of options by body part you want to train, how much time you have and workout style!</li>
                                          <li>Take a 30-day Challenge complete with an interactive calendar and daily email push to keep you on track!</li>
                                          <li>Check in with us in your private support community and make new friends, plus get your questions answered by the coaches!</li>
                                      </ul>

                                      <div className="txt--center d-none d-md-block">
                                          <a href="https://shop.thebettyrocker.com/rock-your-life/" target="_blank" rel="noopener" className="btn btn--md">30-day trial</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </section>

                {/*product box - MEAL PLANS*/}
                <section className="container pb--30">
                  <h2 className="h2--xs txt--uppercase mb--40"><span className="txt--pink">meal</span> plans</h2>

                  <div className="row justify-content-center">
                      {
                          Object.keys(productData).map((id) => (
                              shopifyData.map((item, index) => (
                                  <ProductBoxContainer
                                      key={index}
                                      productId={id}
                                      variantId={item.variants[0].id}
                                      type="meal plan"
                                      item={item}
                                      handlePopup={handlePopup}
                                      handleProduct={handleProduct}
                                      handleShopify={handleShopify}
                                  />
                              ))
                          ))
                      }
                  </div>
                </section>

                {/*product box - SUPPLEMENTS*/}
                {country === 'US' &&
                <section className="bg--lightgray pb--30">
                  <div className="container">
                      <h2 className="h2--xs txt--uppercase mb--40">shop <span className="txt--pink">supplements</span></h2>

                      <div className="row justify-content-center">
                          {
                              Object.keys(productData).map((id) => (
                                  shopifyData.map((item, index) => (
                                      <ProductBoxContainer
                                          key={index}
                                          productId={id}
                                          variantId={item.variants[0].id}
                                          type="supplements"
                                          item={item}
                                          handlePopup={handlePopup}
                                          handleProduct={handleProduct}
                                          handleShopify={handleShopify}
                                      />
                                  ))
                              ))
                          }
                      </div>
                  </div>
                </section>}

                {/*testimonials*/}
                <Testimonial type="home"/>
            </Layout>

            {isOpenPopup &&
            <QuickBuyPopup
              open={isOpenPopup}
              handlePopup={handlePopup}
              shopify={shopifyItem}
              product={clickedProduct}/>
            }
        </>
    )

}

export default Home
